import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import SEO from "../components/seo";

import "../css/global.css";
import "../css/index.css";

const IndexPage = () => {
  return (
    <div>
      <SEO title="Home" />
      <Header>
        <h2 className="intro">
          Emma Williams works primarily with paint, ink and charcoal. Her
          abstract compositions are built up from loose and impulsive marks,
          restrained by predefined visual rules.
        </h2>
      </Header>
      <main>
        <section className="artwork">
          <img src="/img/artwork/image1.jpg" alt="Intersections #1" />
          <img src="/img/artwork/image2.jpg" alt="Intersections #2" />
          <img src="/img/artwork/image3.jpg" alt="Intersections #3" />
          <img src="/img/artwork/image4.jpg" alt="Intersections #4" />
        </section>
      </main>
      <Footer />
    </div>
  );
};

export default IndexPage;
